@import 'styles/mixins';

.marketplacesSort {
  display: grid;
  grid-template-rows: auto;
  row-gap: 32px;

  &__row {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 32px;
    height: 44px;
  }
}

.marketplaces {
  display: grid;
  row-gap: 32px;

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.marketplacesRowOptions {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  column-gap: 20px;

  &__icon {
    font-size: 1.2em;
    cursor: pointer;
  }
}

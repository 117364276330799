@import 'styles/mixins';
@import 'styles/vars';

.select {
  display: flex;
  border: 3px solid transparent;
  border-radius: 10px;

  &__button {
    @include font(14px, 500, 14px);
    padding: 12px 21px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.15s ease-in-out;

    &[data-active='true'] {
      pointer-events: none;
      background-color: $light-gray;
    }
  }

  &WithBorder {
    border: 2px solid $main-blue;
  }
}

@import 'styles/mixins';

.LineGraph .recharts-cartesian-grid-horizontal line:nth-last-child(2),
.LineGraph .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.BarsGraph .recharts-cartesian-grid-horizontal line:first-child,
.BarsGraph .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.BarsGraph .recharts-cartesian-grid > rect,
.LineGraph .recharts-cartesian-grid > rect {
  rx: 16;
}

.LineGraph tspan {
  @include font(16px, 300, 17px);
}

.graphTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 12px;

  border: none !important;
  border-radius: 8px;
  background: #EDEEFF;

  &__label {
    @include font(16px, 400, 18px);
    margin: 0;
  }
}

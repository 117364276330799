@import 'styles/mixins';
@import 'styles/vars';

.postComments {
  &__link {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-color: $main-blue;
    text-decoration-thickness: 4px;
    cursor: pointer;

    &Inactive {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

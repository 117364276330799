@import 'styles/mixins';
@import 'styles/vars';

.profileModal {
  display: flex;
  flex-direction: column;
  padding: 64px 64px 32px 64px;
  width: 50vw;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0;
  }

  &__userInfo {
    display: flex;
    margin-top: 34px;
  }

  &__avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: $avatar-background;
    margin-right: 116px;

    &Img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__info {
    display: block;
  }

  &__login {
    margin-bottom: 26px;
  }

  &__personalData {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 80px;
  }

  &__registrationData {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 80px;
    margin-top: 74px;
  }
}

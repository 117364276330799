@import 'styles/mixins';
@import 'styles/vars';

.placeholder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  background-color: $main-white;

  &__img {
    z-index: 10;

    & svg {
      fill: $main-blue;
    }
  }

  &__text {
    @include font(3.4rem, 400);
    display: block;
    z-index: 100;
    margin-left: 4rem;

    &Light {
      font-size: 2rem;
    }
  }
}

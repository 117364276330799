@import 'styles/vars';
@import 'styles/mixins';

.editLabel {
  display: flex;
  align-items: center;

  &__text {
    @include font(20px, 300, 25px);
    margin: 0 22px 0 0;
  }

  &__icon {
    display: flex;
    margin: auto 0;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.select {
  height: 92px;
  max-width: 100%;
  border-radius: 16px;
}

.selectLinks {
  &__links {
    @include font(26px, 300);
    display: grid;
    grid-auto-flow: row;
    row-gap: 32px;
  }

  &__add {
    @include font(22px, 500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    margin-bottom: 42px;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $main-black;

    & a,
    &:active {
      text-decoration: none;
      color: $main-black;
    }
  }

  &__icons {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 18px;
    align-items: center;

    & svg {
      cursor: pointer;
    }
  }
}

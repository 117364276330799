@import 'styles/mixins';
@import 'styles/vars';

.paginationControls {
  @include font(16px, 400, 17px);
  color: $main-blue;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  &__longArrowLeft {
    transform: rotate(180deg);
    margin-left: 0 !important;
    margin-right: 16px !important;
  }

  &__nextPage {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    & svg {
      font-size: 2em;
      margin-left: 20px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  
    & svg {
      cursor: pointer;
    }

    &Disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__totalPages {
    margin-right: 18px;
    user-select: none;
    min-width: 66px;
    text-align: center;
  }
}

.selector {
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 5000;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-left: 32px;

    & svg {
      transition: all 0.1s ease-in-out;
    }
  }

  &__body {
    position: absolute;
    display: grid;
    grid-template-columns: 60px 60px;
    grid-template-rows: 24px 24px;
    border-radius: 10px;
    background-color: $selector-body;

    transform: translate(-20%, 16px);
    transition: all 0.1s ease-in-out;

    opacity: 0;
    visibility: hidden;

    &Open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 24px;
    border-radius: 10px;
    background-color: $selector-body;
    border: none;
    cursor: pointer;
    user-select: none;

    &Active {
      background-color: $selector-button;
      pointer-events: none;
    }
  }
}

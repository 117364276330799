@import 'styles/mixins';
@import 'styles/vars';

.calendar {
  @include search-pannel-input;
  box-sizing: border-box;
  padding: 11px 16px;
  background-color: $light-gray;
  outline: none;
  border: none;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  min-width: 244px;
  border: none;

  z-index: 5000;
  
  color: $main-black;

  &::placeholder {
    color: $main-gray;
    font-weight: 300;
  }
}

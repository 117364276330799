@import 'styles/mixins';
@import 'styles/vars';

.searchCallByType {
  display: grid;
  grid-auto-flow: column;
  column-gap: 32px;
  width: fit-content;

  &__button {
    @include font(16px, 400, 18px);
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 16px;

    cursor: pointer;
    user-select: none;

    &_selected {
      background-color: $menu-background;
      border-radius: 8px;

      pointer-events: none;
    }
  }
}

.callsRow {
  &__dateAndTime {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    width: fit-content;
    margin: 0 auto;
  }

  &__date {
    font-weight: 500;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__link {
    font-weight: 500;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: initial;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.avatarLoader {
  width: fit-content;

  &__avatar {
    width: 322px;
    height: 436px;
    background-color: $avatar-background;
    margin-bottom: 40px;
    border-radius: 10px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;

    & svg {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.documentModal {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 80vw;
  height: 80vh;
  position: relative;
  border-radius: 10px;
  background-color: $light-gray;

  &__icon {
    font-size: 2em;
    cursor: pointer;

    &Disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: 74vh;
    column-gap: 20px;
    align-items: center;
    padding: 32px 40px;
    width: 100%;
    height: 100%;

    &Image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &Video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &Placeholder {
      width: 400px;
      height: 400px;
      object-fit: cover;
      user-select: none;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.optionPannelButton {
  @include font(16px, 300, 16px);
  padding: 0 14px;
  max-height: 44px;
  border-radius: 10px;
  background-color: $main-blue;
  color: $main-white;
  padding: 10px;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  cursor: pointer;

  &Selected {
    background-color: $menu-background;
    border-radius: 8px;

    pointer-events: none;
  }
}

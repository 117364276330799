@import "styles/mixins";
@import "styles/vars";

.root {
  display: grid;
  row-gap: 32px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.addLinkButton {
  @include font(16px, 300, 16px);
  padding: 0 20px;
  min-height: 64px;
  border-radius: 10px;
  background-color: $main-blue;
  color: $main-white;
}

.removeAllLinksButton {
  border: none;
  outline: none;
  background: transparent;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.removeLinkButton {
  border: none;
  outline: none;
  background: transparent;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.table {
  &__header {
    background: rgba(242, 242, 242, 1);
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
  }

  &__row {
    height: 60px;
  }
}

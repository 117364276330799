@import 'styles/mixins';
@import 'styles/vars';


.editButton {
  width: 100%;
  max-width: 100%;
  height: 92px;

  & svg {
    font-size: 1.5em;
    fill: $main-blue;
  }

  &:hover {
    border-color: $main-blue;
    background-color: $main-blue;
    color: $main-white;

    & svg {
      fill: $main-white;
    }
  }
}

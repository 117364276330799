@import 'styles/mixins';

.currencyRate {
  @include font(18px, 400, 18px);
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  &__input {
    display: flex;
    text-align: center;
    width: 128px;
    padding: 6px;
    border: 1px solid #333333;
    border-radius: 8px;

    font-weight: 600;

    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
  }
}

.currencyRateCard {
  display: flex;
  width: 100%;
  padding: 24px 32px;
  border: 1px solid #333333;
  border-radius: 10px;

  &__icon {
    margin: auto 35px;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-right: 24px;
  border-radius: 10px;
  background-color: $light-gray;
  border: none;
  padding: 24px 52px;
}

.toolbar {
  @include font(18px, 300, 22px);
  background-color: $light-gray;

  &__button {
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
  }

  &__selectBlockType {
    margin-right: 8px;
    width: 140px;
  }

  &__selectFontSize {
    margin-right: 8px;
    width: 64px;
  }
}

.editor {
  @include font(18px, 300, 22px);
  margin-top: 32px;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  & > div {
    height: initial !important;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main-blue;
    border-radius: 8px;
  }
}

.textEditor {
  max-height: 100%;

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &_disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__title {
    margin: 0 0 26px 0;
  }

  &__text {
    min-width: 300px;
    height: 100%;

    &WithControls {
      width: 70%;
      max-width: 70%;
    }

    &Content {
      @include font(20px, 300, 25px);
      padding-right: 84px;
      width: 100%;
      height: 500px;
      box-sizing: border-box;
      resize: none;
      background-color: transparent;
      border: none;

      &:active,
      &:focus {
        outline: none;
      }

      scrollbar-face-color: $main-blue;
      scrollbar-track-color: $scroll-track;
      scrollbar-arrow-color: $scroll-track;
      scrollbar-highlight-color: $scroll-track;
      scrollbar-shadow-color: $main-blue;
      scrollbar-3dlight-color: $scroll-track;
      scrollbar-darkshadow-color: $main-blue;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: $scroll-track;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $main-blue;
        border-radius: 30px;
        outline: none;
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 54px;

    &EditButton {
      margin-bottom: 42px;

      &:hover {
        background-color: $main-blue;
        border-color: $main-blue;
        color: $main-white;
      }

      &:hover svg {
        fill: $main-white;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      flex-wrap: wrap;
    }

    &__text {
      margin-bottom: 42px;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.textEditor {
  &__title {
    @include title-font;
    margin-bottom: 95px;
    margin-top: 0;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin: 0 0 26px 0;
  }

  &__text {
    width: 100%;
    min-width: 300px;
    height: 100%;
    background-color: $menu-background;
    border-radius: 20px;
    padding: 44px;

    &WithControls {
      width: 70%;
      max-width: 70%;
    }

    &Content {
      @include font(20px, 300, 25px);
      padding-right: 84px;
      width: 100%;
      height: 500px;
      box-sizing: border-box;
      resize: none;
      background-color: transparent;
      border: none;

      &:active,
      &:focus {
        outline: none;
      }

      scrollbar-face-color: $main-blue;
      scrollbar-track-color: $scroll-track;
      scrollbar-arrow-color: $scroll-track;
      scrollbar-highlight-color: $scroll-track;
      scrollbar-shadow-color: $main-blue;
      scrollbar-3dlight-color: $scroll-track;
      scrollbar-darkshadow-color: $main-blue;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: $scroll-track;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $main-blue;
        border-radius: 30px;
        outline: none;
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 54px;

    &EditButton {
      margin-bottom: 42px;

      &:hover {
        background-color: $main-blue;
        border-color: $main-blue;
        color: $main-white;
      }

      &:hover svg {
        fill: $main-white;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      flex-wrap: wrap;
    }

    &__text {
      margin-bottom: 42px;
    }
  }
}

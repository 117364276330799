@import 'styles/mixins';
@import 'styles/vars';

.tableStickyMenu {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 16px;
  z-index: 100;
  justify-content: space-between;
  background-color: $main-white;

  &__label {
    @include font(16px, 400);
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    margin-left: 128px;
  }
}

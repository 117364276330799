@import 'styles/mixins';
@import 'styles/vars';

.feedback {
  display: grid;
  row-gap: 36px;
}

.feedbackSort {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 32px;
  align-items: center;
}

@import 'styles/mixins';
@import 'styles/vars';

.calls {
  display: grid;
  row-gap: 32px;

  &__openCallDataButton {
    cursor: pointer;
  }
  
}

.callsSort {
  display: grid;
  grid-auto-flow: row;
  row-gap: 42px;

  &__title {
    @include font(24px, 500);
    margin: 0;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    column-gap: 32px;
  }

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

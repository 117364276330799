@import 'styles/vars';

.deleteBannerButton {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__icon {
    cursor: pointer;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.callDataModal {
  padding: 32px 64px 38px 64px;
  width: 60vw;

  &__title {
    @include font(24px, 500);
    text-align: start;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__infoRow {
    display: flex;
  }

  &__infoLabel {
    @include font(20, 300, 25px);
    margin-right: 16px;
  }

  &__infoValue {
    @include font(20, 500, 25px);
  }

  &__members {
    @include font(20, 500, 25px);

    & span {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #4e51bf;
      text-underline-offset: 5px;
    }
  }

  &__commision {
    @include font(20, 500, 25px);
    border: 2px solid #4e51bf;
    border-radius: 8px;
    padding: 16px 20px;

    & p {
      margin: 0;
    }

    & span {
      color: #4e51bf;
      margin-left: 12px;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.button {
  @include font(14px, 500);
  border-radius: 10px;
  padding: 12px 21px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid $wallet-button;

  &Inactive {
    background-color: $wallet-button;
    pointer-events: none;
  }
}

.bannersPage {
  &__cardsList {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 64px;
    row-gap: 32px;

    margin-top: 64px;
  }

  &__options {
    display: flex;

    & div {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__pagination {
    margin-top: 32px;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.checksPage {
  &__title {
    @include font(18px, 400, 22px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    max-width: 700px;

    &Shorts {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      column-gap: 16px;
      row-gap: 16px;
      margin-bottom: 16px;
    }

    &Longs {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 16px;
      row-gap: 16px;
    }
  }
}

.ruleCard {
  @include font(18px, 400, 22px);
  background-color: $menu-background;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  align-items: center;

  &__toggle {
    margin-right: 16px;
  }
}

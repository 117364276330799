@import 'styles/mixins';
@import 'styles/vars';

.range {
  position: relative;
  width: 100%;

  &__input {
    -webkit-appearance: none;
    margin: 4.5px 0;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: $light-gray;
      border-radius: 10px;
      border: none;
    }

    &::-webkit-slider-thumb {
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $main-blue;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: $light-gray;
      border-radius: 10px;
      border: none;
    }

    &::-moz-range-thumb {
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $main-blue;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5px;
    }
  }
}

.hint {
  @include font(12px, 600, 12px);
  background: $light-gray;
  color: $main-black;
  padding: 4px 22px;
  position: absolute;
  border-radius: 60px;
  left: 100%;
  transform: translateY(-120%);
  user-select: none;

  &Down {
    transform: translateY(120%);
  }
}

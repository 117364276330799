@import 'styles/mixins';
@import 'styles/vars';

.addUserModal {
  display: flex;
  flex-direction: column;
  padding: 64px 64px 32px 64px;
  width: 50vw;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0 0 69px 0;
  }

  &__error {
    @include font(16px, 500, 22px);
    color: #f82f2fd8;
  }

  &__avatar {
    @include font(22px, 500, 27px);
    display: flex;
    align-items: center;
    cursor: pointer;

    & > span {
      margin-right: 42px;
    }

    &Input {
      position: absolute;
      z-index: 1;
      opacity: 0;
      display: none;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 50px;
    margin-bottom: 16px;

    &Input {
      width: 100%;
      padding: 29px 37px;
      margin-right: 0;
    }

    &InputPassword {
      width: 100%;
      padding: 29px 20px 29px 37px;
      margin-right: 0;
    }

    &Full {
      grid-column: 1 /3;
    }
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.tooltip {
  @include font(18px, 500, 20px);
  background-color: $main-tooltip;
  padding: 12px 26px;
  font-family: Montserrat;
  color: $notification-text;
  border-radius: 8px;
  z-index: 5000;

  &Menu {
    background-color: $menu-tooltip-2;
    padding: 9px 46px;
    color: $main-white;

    transform: translate(16px, -2px);
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.feedSettings {
  display: flex;

  &__layouts {
    margin-right: 42px;
  }

  &__selects {
    display: flex;
    flex-direction: column;
    max-width: 350px;
  }

  &__category {
    margin-bottom: 16px;

    &Title {
      @include font(18px, 400, 22px);
      display: block;
      margin-bottom: 8px;
    }
  }

  &__select {
    @include font(18px, 500, 22px);
    padding: 21px 24px;

    & > span {
      display: inline;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.layoutCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 24px;
  background-color: $card-bg;

  &__header {
    @include font(18px, 400, 22px);
    display: flex;
    align-items: center;

    & > span {
      margin-left: 22px;
    }
  }
}

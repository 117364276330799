@import 'styles/vars';
@import 'styles/mixins';

.marketplaceItemModal {
  display: grid;
  grid-auto-flow: row;
  row-gap: 32px;
  width: fit-content;
  padding: 64px;

  &__title {
    @include font(26px, 600);
    margin: 0;
  }

  &__info {
    display: grid;
    grid-template-columns: 196px 1fr;
    column-gap: 64px;
  }

  &__avatar {
    width: 196px;
    height: 196px;
    background-color: $avatar-background;
    border-radius: 8px;

    & img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__contacts {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 64px;
    row-gap: 32px;
    justify-content: flex-start;
  }

  &__description {
    max-width: 768px;
  }
}

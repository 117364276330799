@import 'styles/mixins';

.complaintOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__icon {
    font-size: 1.2em;
    margin-right: 16px;
    cursor: pointer;
  }

  &__button {
    @include table-options-button;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.complaints {
  display: grid;
  row-gap: 36px;

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.complaintsSort {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: 32px;
  align-items: center;
  height: 44px;
}

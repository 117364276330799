@import 'styles/mixins';
@import 'styles/vars';

.adminTableRow {
  &__avatar {
    width: 50px;
    height: 50px;
    background-color: $avatar-background;

    & img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.feedbackMessage {
  @include font(20px, 500, 24px);
  margin-bottom: 40px;
  padding-right: 70px;

  &__message {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    justify-content: flex-start;
    column-gap: 124px;
    row-gap: 24px;
    background-color: $light-gray;
    border-radius: 10px;
    padding: 26px 42px;
    overflow: hidden;
  }

  &__leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__rightBlock {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 600;
    margin: 0;
  }

  &__text {
    font-weight: 300;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__fullLink {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;

    &Icon {
      display: flex;
      align-items: center;
      margin-left: 12px;
    }
  }

  &__info {
    display: flex;
  }

  &__avatar {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: $message-avatar;
    margin-right: 30px;
    position: relative;

    & > img {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__contacts {
    @include font(20px, 500, 24px);
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__name,
  &__email {
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__name {
    margin-bottom: 6px;
  }

  &__email {
    font-weight: 300;
  }

  &__date {
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-weight: 300;
    margin-top: 24px;

    & b {
      display: inline-block;
      font-weight: 600;
      margin-left: 12px;
    }
  }

  &__reply {
    display: flex;
    min-height: 60px;
    margin-top: 20px;
  }

  &__button {
    padding: 16px 46px;

    &:hover {
      background-color: $main-blue;
      border-color: $main-blue;;
      & svg path {
        fill: $main-white;
      }
    }
  }
}

@import 'styles/vars';

.modal {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 5000;

  &__back {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-black;
    opacity: 0.5;
    user-select: none;
    z-index: 100;
  }

  &__body {
    position: relative;
    border-radius: 30px;
    background-color: $main-white;
    opacity: 1;
    z-index: 200;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__closeButton {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
    z-index: 1000;
  }

  &Hide {
    opacity: 0;
  }
}

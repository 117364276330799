.goBackButton {
  background-color: transparent;
  border: none;

  &__icon {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  position: relative;

  &AlignStart {
    text-align: start;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__header {
    @include font(16px, 400, 17px);
    background-color: $table-header;
    position: sticky;
    top: 0;
  }

  &__body {
    @include font(16px, 400, 17px);
    user-select: none;
  }

  &__row {
    &Even {
      background-color: $table-header;
    }

    &Odd {
      background-color: $table-row;
    }
  }

  &__td, & td {
    padding: 10px;
    text-align: inherit;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &AlignStart {
      text-align: start !important;
    }

    &AlignCenter {
      text-align: center !important;
    }

    &WithoutPadding {
      padding: 0 !important;
    }
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.editBanner {
  display: flex;

  &__content {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 90px;
    width: 100%;
  }

  &__contentBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__inputBlock {
    margin-top: 32px;
  }

  &__input {
    @include font(20px, 400, 24px);
    padding: 14px 16px;
    max-width: 400px;
  }

  &__button {
    width: 300px;
    height: 75px;
    margin-top: 40px;
  }

  &__label {
    @include font(20px, 300, 24px);
    display: block;
    margin-bottom: 16px;
  }

  &__loadImage {

    &Title {
      display: flex;
      align-items: center;
      margin-bottom: 42px;
      cursor: pointer;

      @include font(26px, 500, 32px);
    }

    &Icon {
      margin-left: 32px;
    }
  }

  &__image {
    display: flex;
    width: 400px;
    height: 240px;

    background-color: $light-gray;

    & img {
      display: block;

      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  &__editor {
    width: 100%;
    margin-top: 40px;
  }

}

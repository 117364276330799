@import 'styles/mixins';

.callDataModal {
  padding: 32px 64px 64px 64px;
  width: 60vw;

  &__title {
    @include font(24px, 500);
    text-align: start;
  }

  &__player {
    margin-bottom: 32px;

    & video {
      max-height: 50vh;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  &__video {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.graphCard {
  display: flex;
  flex-direction: column;
  padding: 32px 42px;
  background-color: $menu-background;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;

  &__title {
    @include font(22px, 500);
    margin: 0;
  }

  &__graph {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 300px;
    min-width: 300px;
    margin-top: 40px;
  }
}

.graphErrorFallback {
  @include font(24px, 400);
  color: $main-blue;
}

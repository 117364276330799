@import 'styles/mixins';
@import 'styles/vars';

.textarea {
  @include font(18px, 300, 22px);
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  margin-right: 24px;
  border-radius: 10px;
  background-color: $light-gray;
  border: none;
  padding: 24px 52px;

  resize: none;

  &:active, &:focus {
    outline: none;
  }
}

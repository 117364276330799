@import 'fonts';

@mixin font($size: 16px, $weight: 500, $lineHeight: $size, $style: normal) {
  font-family: 'Montserrat';
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  line-height: $lineHeight;
}

@mixin small-font($weight: 300, $style: normal) {
  @include font(14px, $weight, 20px, $style);
}

@mixin normal-font($weight: 500, $style: normal) {
  @include font(24px, $weight, 29px, $style);
}

@mixin medium-font($weight: 500, $style: normal) {
  @include font(30px, $weight, 37px, $style);
}

@mixin large-font($weight: 500, $style: normal) {
  @include font(40px, $weight, 49px, $style);
}

@mixin title-font($weight: 600, $style: normal) {
  @include font(50px, $weight, 61px, $style);
}

@mixin search-pannel-input($width: 300px, $height: 44px) {
  @include font(16px, 300, 18px);
  padding-top: 14px;
  padding-bottom: 14px;
  width: $width;
  height: $height;
}

@mixin table-options-button {
  @include font(16px, 400, 17px);
  padding: 6px 32px;
  border-radius: 4px;
  border-width: 1px;
}

@mixin table-sort-button {
  @include font(14px, 300, 17px);
  padding: 0 12px;
  border-radius: 10px;
}

@import 'styles/mixins';
@import 'styles/vars';

.radioButton {
  display: flex;
  padding: 4px;
  background-color: $main-white;
  border-radius: 50%;
  border: 2px solid $main-blue;

  cursor: pointer;

  & > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $main-white;
    animation: 0.3s off;
  }

  &Selected {
    & > div {
      background-color: $main-blue;
      animation: 0.3s on;
    }
  }
}

@keyframes on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes off {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

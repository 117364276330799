@import 'styles/mixins';
@import 'styles/vars';

.filterUsers {
  @include font(16px, 300, 17px);
  display: flex;
  align-items: center;
  margin-right: 32px;

  &__title {
    margin-right: 18px;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.input {
  @include normal-font;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.5em 1em;
  border-radius: 10px;

  color: $main-black;
  background-color: $light-gray;

  &__input {
    border: none;
    outline: none;

    padding: 0;
    margin: 0;

    color: inherit;
    background-color: transparent;

    width: 100%;

    font-size: inherit;
    font-style: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;

    &::placeholder {
      opacity: 0.8;
      font-weight: 300;
    }

    &,
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  &__prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
    margin-right: 0.5em;
    font-size: 1em;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
    margin-left: 0.5em;
    font-size: 1em;
    cursor: pointer;
  }

  &Disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.graphBars {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  min-height: 200px;
}

.graphBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 90px;
  max-width: 160px;

  &__label {
    @include font(22px, 300, 27px);
    color: $graph-label;
    margin-top: 12px;
  }
}

@import 'styles/vars';

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  background: $scroll-track;
  border-radius: 30px;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: $main-blue;
  border-radius: 30px;
  left: 0;
  right: 0;
  opacity: 1 !important;
  transition: opacity 0.2s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px;
}

.simplebar-track.simplebar-horizontal {
  bottom: 0;
  height: 8px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  height: 8px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

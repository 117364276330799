@import 'styles/mixins';
@import 'styles/vars';

.financeStatistics {
  &__topGraphs {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    column-gap: 40px;
    position: absolute;
    top: -32px;
    right: 0;
  }

  &__donutGraph {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }

  &__label {
    @include font(12px, 300, 14px);
    width: 124px;
    text-align: end;
    margin-left: auto;
  }

  &__charts {
    margin-top: 64px;
  }

  &__title {
    @include font(24px, 500, 29px);
    margin: 0 0 56px 0;
  }

  &__list {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-template-columns: 1fr 1fr;
    column-gap: 64px;
    row-gap: 32px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1500px) {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
    }
  }
}

@import 'styles/mixins';

.ranges {
  display: flex;
  gap: 18px;

  &__range {
    @include font(14px, 500, 16px);
    display: flex;
    align-items: center;
    gap: 18px;

    & > span {
      white-space: nowrap;
      opacity: 0.7;
    }
  }

  &__input {
    @include search-pannel-input;
    width: 160px;
  }
}

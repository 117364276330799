@import 'styles/vars';
@import 'styles/mixins';

.bannerCard {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 480px;
  width: 400px;
  border-radius: 16px;
  background-color: $selector-body;
  overflow: hidden;

  padding: 24px;

  &__info {
    margin-right: 16px;
  }

  &__title {
    @include font(22px, 500, 28px);
    margin-bottom: 8px;
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
  }

  &__subtitle {
    @include font(16px, 400, 20px);
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: fit-content;
  }
}

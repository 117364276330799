.react-date-picker__wrapper {
  border: none;
}

.react-date-picker__wrapper button {
  padding: 0;
}

.react-date-picker__clear-button {
  margin-right: 8px;
}

.react-date-picker__inputGroup {
  margin-right: 16px;
}

.react-calendar__tile--now {
  background-color: #4e52bf80 !important;
  color: #FFFFFF !important;
  border-radius: 4px;
}

.react-calendar__tile--active {
  background-color: #4e51bf !important;
  color: #FFFFFF !important;
  border-radius: 4px;
}

.react-calendar-custom {
  background-color: #F5F5F5;
  border-color: #c4c4c490;
  border-radius: 8px;
  margin: 8px 0;
}

@import 'styles/mixins';
@import 'styles/vars';

.verifications {
  display: grid;
  row-gap: 36px;

  &__title {
    @include font(24px, 500, 29px);
    margin: 0;
  }

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.verificationSort {
  display: grid;
  grid-auto-flow: column;
  column-gap: 32px;
  align-items: center;
  height: 44px;
}

.verificationControls {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  width: 350px;
  margin-right: 4px;

  &__button {
    @include font(16px, 400, 17px);
    padding: 6px 32px;
    border-radius: 4px;
    border-width: 1px;
  }

  &__icons {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    margin-right: 12px;
  }

  &__icon {
    font-size: 1.2em;
    cursor: pointer;
  }
}

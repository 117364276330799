@import 'styles/mixins';
@import 'styles/vars';

.postsSearchPannel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  & > div, & > button {
    margin-bottom: 32px;
    margin-right: 32px;
  }
}

.posts {
  display: grid;
  row-gap: 36px;

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.postOptions {
  display: grid;
  grid-template-columns: repeat(4, 16px);
  column-gap: 18px;
  font-size: 1.25em;
  justify-content: center;

  & svg {
    cursor: pointer;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.editProfile {
  @include font(20px, 300, 24px);
  display: grid;
  grid-template-columns: 322px minmax(auto, 800px);
  justify-content: space-between;

  &__avatar {
    width: 322px;
    height: 436px;
    background-color: $avatar-background;
    margin-bottom: 40px;
    border-radius: 10px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 60px;
  }

  &__info {
    margin-left: 70px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 46px;
  }

  &__title {
    display: flex;
    align-items: center;

    &OnTop {
      margin-top: 12px;
      margin-bottom: auto;
    }
  }

  &__inputWrapper {
    width: 340px;
  }

  &__input {
    @include font(18px, 400, 22px);
    padding: 12px 22px 12px 33px;
    width: 100%;

    & svg path {
      stroke: $main-blue;
    }
  }

  &__inputPassword {
    @include font(18px, 400, 22px);
    padding: 12px 16px 12px 33px;
    width: 100%;

    & svg {
      width: 24px !important;
      height: 24px !important;
      opacity: 0.8;
    }

    & svg path {
      fill: $main-blue;
    }
  }

  &__select {
    @include font(18px, 400, 22px);
    padding: 12px 22px 12px 33px;
  }

  &__option {
    cursor: pointer;
  }
}

.permission {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 8px;
  }

  &Item {
    margin-top: 16px;
  }

  & svg {
    cursor: pointer;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.formItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  
  &__label {
    @include font(24px, 400, 32px);
    margin-bottom: 8px;
  }
}

.restorePassword {
  display: grid;
  row-gap: 42px;

  @media screen and (max-height: 900px) {
    row-gap: 24px;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  background-color: $light-gray;
  border-radius: 40px;
  padding: 64px;

  &__logo {
    margin: 0 auto 45px 0;
  }

  &__passwordInputWrapper {
    display: flex;
    position: relative;
  }

  &__hidePasswordIcon {
    position: absolute;
    width: fit-content;
    height: fit-content;

    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;
  }

  &__input {
    @include normal-font;
    background-color: $main-white;
    outline: none;
    border: none;
    border-radius: 20px;
    padding: 36px;
    padding-right: 64px;
    width: 550px;

    &Code {
      text-align: start;
    }

    &Password {
      padding-right: 60px !important;
    }
  }

  &__restoreButton {
    @include font(26px, 400, 32px);
    margin-bottom: 94px;
    color: $main-black;
    text-decoration: underline;
    text-underline-offset: 16px;
    text-decoration-color: $main-black;
    border-radius: 20px;
  }

  &__submitButton {
    width: 100%;
    height: 100px;
    font-size: 40px;
    border-radius: 20px;

    &:hover {
      color: $main-blue;
      background: $main-white;
      border: 4px solid $main-white;
    }
  }

  &__label {
    @include font(32px, 500, 39px);
    margin-bottom: 16px;
  }

  @media screen and (max-height: 900px) {
    padding: 32px 42px;
    width: 80vw;
    max-width: 400px;
  
    &__logo {
      height: 128px;
      width: 256px;
    }
  
    &__input {
      @include font(16px, 400);
      padding: 24px;
      width: 100%;
    }
  
    &__restoreButton {
      @include font(16px, 400);
      margin-bottom: 32px;
    }
  
    &__submitButton {
      height: 70px;
      font-size: 24px;
      border-radius: 20px;
    }
  
    &__label {
      @include font(16px, 500);
      margin-bottom: 8px;
    }
  }
}

.message {
  &__incorrect {
    height: 0px;
    font-size: 0;
    transition: all 0.2s ease-in-out;

    &Icon {
      font-size: 2em;
      margin-right: 42px;
    }

    &Visible {
      @include font(22px, 400, 27px);
      display: flex;
      align-items: center;
      height: auto;
      max-width: 550px;
      padding: 35px;
      margin-bottom: 42px;
      color: $main-white;
      background-color: $warning;
      border-radius: 20px;
    }
  }

  &__warning {
    display: flex;
    align-items: center;
    color: $warning;
    margin: 29px 0 36px 0;
    font-size: 0;
    transition: all 0.2s ease-in-out;

    &Icon {
      display: flex;
      align-items: center;
      margin-right: 22px;
    }

    &Visible {
      @include font(26px, 400, 32px);
    }
  }

  @media screen and (max-height: 840px) {
    &__incorrect {
      &Visible {
        @include font(14px, 400);
        padding: 16px;
        margin-bottom: 24px;
      }
    }
  
    &__warning {
      margin: 16px 0 24px 0;
      font-size: 0;
      transition: all 0.2s ease-in-out;
  
      &Visible {
        @include font(16px, 400);
      }
    }
  }
}

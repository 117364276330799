@import 'styles/mixins';
@import 'styles/vars';

.complaintModal {
  display: flex;
  flex-direction: column;
  padding: 64px 64px 64px 64px;
  width: 50vw;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0;
  }

  &__content {
    margin-top: 30px;
  }

  &__block {
    @include font(20px, 300, 24px);
    display: grid;
    grid-template-columns: 128px 1fr;
    column-gap: 64px;
    row-gap: 40px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &Title {
      margin: 0;
    }

    &Description {
      margin: 0;
    }

    &Bold {
      font-weight: 600;
    }
  }
}

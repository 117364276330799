@import 'styles/mixins';
@import 'styles/vars';

.contentMenu {
  @include font(16px);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  column-gap: 64px;
  row-gap: 42px;
  align-items: flex-start;
  margin-top: 80px;

  &__left,
  &__right {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 42px;
    max-width: 100%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  &__select {
    height: 92px;
    max-width: 100%;
    border-radius: 16px;
  }
}

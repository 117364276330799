@import 'styles/mixins';
@import 'styles/vars';

.onboarding {
  &__title {
    @include title-font;
    margin-bottom: 64px;
    margin-top: 0;
  }

  &__addDescBlock {
    @include font(20px, 500, 24px);
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    width: fit-content;
  }

  &__descList {
    & > * + * {
      margin-top: 32px;
    }
  }

  &__label {
    @include font(26px, 500, 32px);
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    cursor: pointer;

    & > img {
      margin-left: 32px;
    }
  }

  &__button {
    width: 320px;
    margin-bottom: 64px;
  }

  &__image {
    display: block;
    object-fit: contain;
    width: 320px;
    height: 410px;
    background-color: $light-gray;
    margin-bottom: 64px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 64px;
  }

  &__contentBlock {
    width: 100%;
    max-width: 676px;
    margin-right: 32px;

    & > * + * {
      margin-top: 32px;
    }
  }

  &__editor {
    height: fit-content;

    &:not(:last-child) {
      margin-bottom: 64px;
    }
  }

  &__descBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__descBlockTitle {
    @include font(26px, 500, 32px);
    margin: 0;
  }

  &__descTip {
    @include font(14px, 300, 18px);
    margin-top: 4px;
    width: fit-content;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.editPost {
  display: grid;
  grid-template-columns: 370px 500px;
  justify-content: space-between;
  column-gap: 128px;

  &__title {
    @include font(26px, 500, 32px);
    margin: 0;
  }

  &__controls {
    display: grid;
    row-gap: 26px;
    margin-top: auto;
  }

  &__button {
    height: 86px;
    width: 100%;
    max-width: 320px;
    padding: 24px 32px;
    border-radius: 10px;
  }
}

.editPostText {
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 42px;

  &__description {
    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: 56px;
    align-items: center;
    height: fit-content;
  }

  &__text {
    @include font(20px, 300, 24px);
    max-width: 460px;
    margin: 0;
  }

  &__editIcon {
    font-size: 2em;
    align-self: flex-end;
    cursor: pointer;
  }

  &__textarea {
    @include font(22px, 300, 27px);
    border-radius: 10px;
  }
}

.editPostMedia {
  display: grid;
  row-gap: 42px;
  height: fit-content;

  &__controls {
    display: grid;
    justify-content: center;
    row-gap: 26px;
    margin-top: auto;
  }

  &__arrow {
    cursor: pointer;

    &Disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__media {
    display: grid;
    grid-template-columns: 32px 1fr 32px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    column-gap: 16px;
    width: 100%;
    height: 370px;

    & img,
    & video {
      max-width: 100%;
      max-height: 350px;
      object-fit: contain;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.toggle {
  display: flex;
  border-radius: 40px;
  background-color: $main-white;

  padding: 6px;

  cursor: pointer;

  &__thumb {
    display: flex;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: $toggle-inactive;
    transition: all 0.2s ease-in-out;
    margin: 0 20px 0 0;

    &Active {
      background-color: $toggle-active;
      margin: 0 0 0 20px;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.categories {
  display: grid;
  row-gap: 42px;

  &__title {
    @include title-font(600);
    margin: 0;
  }

  &__subtitle {
    @include medium-font(400);
    margin: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 500px);
    row-gap: 30px;
    column-gap: 64px;
  }

  &__add {
    display: flex;
    height: 56px;
    margin-bottom: 32px;
  }

  &__buttons {
    display: flex;
    gap: 8px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    width: 56px;
    height: 56px;
  }

  &__input {
    @include font(18px, 400, 22px);
    padding: 16px 24px 16px 32px;
    width: 370px;
    margin-right: 29px;
  }
}

.category {
  display: flex;
  height: 56px;

  &Input {
    @include font(18px, 400, 22px);
    padding: 16px 24px 16px 32px;
    width: 370px;
    margin-right: 29px;
  }

  &Button {
    padding: 12px;
    border-radius: 8px;
  }
}

$main-blue: #4E51BF;
$main-white: #FFFFFF;
$main-black: #000000;
$main-pink: #FE85A2;
$light-blue: lighten($main-blue, 20%);
$main-gray: #515151;
$light-gray: #F5F5F5;
$menu-background: #F2F2F2;
$select-body: #FAFAFA;
$warning: #EB3D3D;
$scroll-track: #c4c4c490;
$photo-background: #ECECEC;
$table-header: #FCFCFC;
$table-row: #F4F4F4;
$avatar-background: #D9DAFF;
$message-avatar: #C4C4C4;
$graph-label: #242424;
$comment-border: #AFAFAF;
$wallet-button: #EDEEFF;
$menu-tooltip:  #aaaaaa80;
$menu-tooltip-2: #AAAAAA;
$main-tooltip:  #EDEEFF;

$selector-body: #F4F4F4;
$selector-button: #EDEEFF;

$notification-text: #5C5C5C;

$menu-width: 410px;
$mobile-menu-width: 160px;

$toggle-inactive: #C4C4C4;
$toggle-active: #4E51BF;
$card-bg: #F2F2F2;

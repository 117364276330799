@import 'styles/mixins';

.confirmModal {
  display: grid;
  grid-template-rows: auto;
  row-gap: 32px;
  padding: 128px 164px 80px 164px;

  &__title {
    @include font(32px, 500);
    text-align: center;
    margin: 0 0 64px 0;
  }

  &__button {
    @include font(25px, 500);
    padding: 28px 64px;
  }
}

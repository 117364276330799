@import 'styles/mixins';
@import 'styles/vars';

.overallStatistics {
  margin-top: 8px;
  margin-right: 64px;

  &__stats {
    margin-top: 32px;
  }

  &__periodSelector {
    margin-bottom: 36px;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-items: space-between;
  }

  &__list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 24px;

    & > div {
      margin-bottom: 36px;
    }

    &Title {
      @include font(24px, 500);
      position: sticky;
      top: 0;
      margin-bottom: 16px;
      padding-bottom: 16px;
      background-color: $main-white;;
    }

    &:not(:last-child) {
      margin-right: 128px;
    }
  }
}

.currencyRate {
  display: grid;

  grid-template-columns: max-content;
  gap: 32px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    margin-left: 32px;
    cursor: pointer;
  }
}

@import 'styles/mixins';

.textBlock {
  display: block;

  &__title {
    @include font(20px, 300, 24px);
    margin-bottom: 8px;
    margin-top: 0;
    text-align: start;
  }

  &__content {
    @include font(20px, 500, 24px);
    margin-bottom: 8px;
    text-align: start;
    word-break: break-all;
    white-space: pre-wrap;
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.periodSelector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__selectors {
    @include font(16px, 300, 16px);

    display: flex;
    gap: 22px;
    margin-top: 32px;
  }

  &__calendar {
    width: 200px;
    padding: 14px 16px;
    border-radius: 10px;
    margin-top: 10px;
  }

  &__body {
    display: flex;
    padding: 16px 8px;
    width: fit-content;
    height: 90px;
    background-color: $menu-background;
    border-radius: 50px;
  }

  &__button {
    @include font(24px, 400);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    margin: 0 8px;
    max-width: fit-content;
    width: 100%;

    border-radius: 50px;
    background-color: transparent;
    color: $main-black;

    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: lighten($main-blue, 30);
      color: $main-white;
    }

    &[data-active='true'] {
      pointer-events: none;
      background-color: $main-blue;
      color: $main-white;
    }
  }
}

@import "styles/mixins";
@import "styles/vars";

.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: $menu-background;
  padding: 32px 24px;
  z-index: 500;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &__logout {
    margin-top: auto;
  }

  &__button {
    width: 100%;
  }

  &__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    user-select: none;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  &__logo {
    position: relative;
    width: fit-content;
    margin-bottom: 32px;
  }

  &__hideButton {
    position: absolute;
    right: 32px;
    cursor: pointer;
  }

  &Mobile {
    padding: 32px;

    width: $mobile-menu-width;

    &__link {
      justify-content: center;
    }

    &__button {
      background-color: $main-blue;
    }

    &__logo {
      left: 16px;
      margin: 12px 0 24px 0;
    }

    &__hideButton {
      right: 16px;
      top: 16px;
    }
  }

  @media screen and (max-height: 830px) {
    &__logo {
      margin-bottom: 24px;
    }

    &__link {
      margin-bottom: 14px;
    }
  }
}

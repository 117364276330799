@import 'styles/mixins';
@import 'styles/vars';

.navlink {
  @include font(1.5rem, 500, 1.6rem);
  display: flex;
  align-items: center;
  color: $main-black;
  opacity: 0.4;
  text-decoration: none;
  white-space: nowrap;

  &Active {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 16px;
    text-decoration-color: $main-blue;
    text-decoration-thickness: 3px;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.feedbackMessage {
  &__answer {
    @include font(20px, 300, 24px);
    margin-bottom: 40px;
    margin-top: 16px;
    padding: 18px 50px;
    background-color: $light-gray;
    border-radius: 10px;

    word-break: break-all;

    & > span {
      font-weight: 500;
    }
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.verifications {
  &__buttons {
    display: grid;
    grid-auto-flow: row;
    row-gap: 32px;
    max-width: 600px;
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.editBannerButton {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    cursor: pointer;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.commentBlock {
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  border-bottom: 0.5px solid $comment-border;

  &Nested {
    border-bottom: none;
    border-top: 0.5px solid $comment-border;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px 0;

    // &Open {
      // align-items: flex-start;
    // }
  }

  &__body {
    padding: 16px 0 8px 64px;
  }

  &__avatar {
    display: block;
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    border-radius: 50%;
    background-color: $avatar-background;

    & > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &__username {
    @include font(16px, 500);
    display: flex;
    align-items: center;
    height: 38px;
    width: 100%;
    min-width: 160px;
    max-width: 160px;
    margin: 0 36px;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__text {
    @include font(16px, 300);
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    height: 100%;
    width: 100%;
    margin: 11px 0;

    // &Full {
      height: auto;
      word-wrap: break-word;
      white-space: normal;
      word-break: break-all;
    // }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-left: 22px;
  }

  &__icon {
    font-size: 1.4em;

    & svg {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }
  }
}

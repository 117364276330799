@import 'styles/mixins';
@import 'styles/vars';

.button {
  @include normal-font;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: $main-blue;
  background: transparent;
  border: 4px solid $main-blue;
  border-radius: 16px;

  cursor: pointer;

  &__text {
    display: block;
    user-select: none;

    &WithoutOverflow {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: max-content;
  }

  &Default {
    &:hover {
      color: $main-white;
      background: $main-blue;
    }
  }

  &Solid {
    color: $main-white;
    background: $main-blue;
  }

  &Gray {
    color: $main-black;
    background: $light-gray;
    border: 4px solid $light-gray;
  }

  &Disabled {
    pointer-events: none;
    opacity: 0.57;
  }

  &WithIcon {
    justify-content: space-between;

    .button__text {
      margin-right: 32px;
    }
  }
}

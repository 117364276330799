@import 'styles/mixins';
@import 'styles/vars';

.usersTable {
  display: grid;
  row-gap: 36px;
  position: relative;

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.usersSort {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  column-gap: 32px;
}

.container {
  display: grid;
  row-gap: 32px;
}

.usersSearchPannel {
  @include font(16px, 300, 17px);
  display: flex;
  align-items: center;
}

.userTableRowOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  font-size: 1.2em;

  &__group {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    align-items: center;
    & svg {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

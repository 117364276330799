@import 'styles/mixins';
@import 'styles/vars';

.verificationModal {
  display: flex;
  flex-direction: column;
  padding: 64px 64px 32px 64px;
  width: fit-content;
  min-width: 50vw;
  min-height: 300px;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0 0 69px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 125px;
    row-gap: 25px;
    margin-right: 254px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
  }

  &__button {
    @include font(18px, 500, 22px);
    padding: 17px 96px;
    border-radius: 10px;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

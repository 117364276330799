@import 'styles/mixins';

.postCommentsModal {
  @include font(20px, 300);
  position: relative;
  width: 60vw;
  height: 80vh;
  margin: 100px 64px 64px 64px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__infoRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__comments {
    display: flex;
    flex-direction: column;
    margin-top: 42px;
  }

  &__title {
    @include font(20px, 300);
  }

  &__list {
    margin-right: 46px;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.connectCard {
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    column-gap: 64px;
  }

  &__title {
    @include title-font;
    margin-bottom: 64px;
    margin-top: 0;
  }

  &__label {
    @include font(26px, 500, 32px);
    display: flex;
    align-items: center;
    margin: 0 0 42px 0;
    cursor: pointer;

    & > img {
      margin-left: 32px;
    }
  }

  &__button {
    width: 320px;
    margin-top: 64px;
  }

  &__image {
    display: block;
    object-fit: contain;
    width: 320px;
    height: 410px;
    background-color: $light-gray;
    margin-bottom: 64px;
  }

  &__textarea {
    margin: 8px 0 32px 0;
  }
}

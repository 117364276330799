@import "styles/mixins";
@import "styles/vars";

.modal {
  display: flex;
  flex-direction: column;
  padding: 64px 64px 32px 64px;
  width: 50vw;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0 0 69px 0;
  }

  &__error {
    @include font(16px, 500, 22px);
    color: #f82f2fd8;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &Input {
      width: 100%;
      padding: 29px 37px;
      margin-right: 0;
    }

    &Field {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

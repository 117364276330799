@import 'styles/mixins';
@import 'styles/vars';

.contentLayout {
  overflow: auto;
  padding: 70px 80px;

  &__header {
    position: relative;
  }

  &__title {
    @include title-font;
    margin-top: 0;
    margin-bottom: 42px;
  }

  &__navigation {
    margin-bottom: 42px;;
  }

  &__goBackButton {
    position: absolute;
    top: 12px;
    left: -64px;
  }
}

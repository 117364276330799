@import 'styles/vars';

.photoModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 100px 60px 100px;

  &__imageContainer {
    width: 666px;
    height: 410px;
    background-color: $photo-background;
    margin-bottom: 16px;
  }

  &__button {
    width: 540px;
    margin-top: 24px;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__imageInput {
    position: absolute;
    z-index: 1;
    opacity: 0;
    display: none;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 64px;
  right: 64px;
  padding: 18px 32px;
  max-width: 500px;

  z-index: 6000;

  color: $notification-text;
  background-color: $wallet-button;
  border-radius: 10px;

  &Error {
    background-color: $warning;
    color: $main-white;
    border-radius: 10px;
  }

  &__text {
    @include font(18px, 500, 22px);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    margin-right: 32px;
  }

  &__loader {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 5px solid $main-blue;
    border-bottom-color: lighten($comment-border, 10);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 16px;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.Toastify__toast-icon {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  margin-left: -16px;
}

@import 'styles/mixins';
@import 'styles/vars';

.walletTable {
  &__title {
    @include font(30px, 400);
  }

  &__table {
    @include font(18px, 300, 22px);

    width: 100%;
    border-collapse: collapse;
    margin-right: 32px;
  }

  &__thead {

    width: 100%;

    & td {
      border-bottom: 2px solid $scroll-track;
      padding: 0 42px 16px 0;
      min-width: fit-content;
      white-space: nowrap;
      background-color: $main-white;
    }
  }

  &__row {
    & td {
      border-bottom: 2px solid $scroll-track;
      padding: 32px 0;
      height: 105px;
    }
  }

  &__bold {
    font-weight: 500;
  }

  &__coins {
    text-align: center;
  }

  &__email,
  &__name {
    display: block;
    box-sizing: border-box;
    width: 80px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    width: 240px;
    max-width: 240px;
  }
}

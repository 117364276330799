.admins {
  display: grid;
  row-gap: 36px;

  &__paginationTop {
    display: flex;
    justify-content: space-between;
  }
}

.adminsSort {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  column-gap: 32px;
}

.adminsTableControls {
  display: grid;
  grid-auto-flow: column;
  column-gap: 18px;
  align-items: center;
  justify-content: flex-end;

  &__icon {
    font-size: 1.5em;
    cursor: pointer;
  }
}

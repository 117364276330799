@import 'styles/mixins';
@import 'styles/vars';

.walletSort {
  &__block {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: 50px;
    column-gap: 32px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }

  &__sortOptions {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-template-rows: 50px;
    column-gap: 8px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }
}

.wallet {
  &__paginationTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
  }
}

.walletRowOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__money {
    margin-right: 50px;
    white-space: nowrap;
  }

  &__button {
    @include font(14px, 500);
    padding: 10px 26px 12px 26px;
    border-radius: 4px;
    border-width: 1px;
    margin-right: 8px;
  }
}


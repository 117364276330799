@import 'styles/mixins';
@import 'styles/vars';

.audioWave {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 256px;

  &__audio {
    display: grid;
    grid-template-columns: 42px 1fr 42px;
    column-gap: 16px;
    align-items: center;
  }

  &__wave {
    width: 100%;
  }

  &__duration {
    @include font(12px, 300);
  }

  &__controls {
    display: grid;
    grid-template-columns: auto 160px;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    margin-top: 32px;

    &Disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__playButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: $menu-background;
    border-radius: 50%;
    border: none;
  }

  &__spinner {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

@import 'styles/mixins';
@import 'styles/vars';

.checkbox {
  width: 1em;
  height: 1em;
  cursor: pointer;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid $main-black;
  border-radius: 2px;

  &:checked {
    border: 1px solid $main-blue;
    background: $main-blue;
  }
}

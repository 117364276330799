@import "styles/mixins";
@import "styles/vars";

.navlink {
  @include font(1.2rem, 500, 1.4rem);
  display: flex;
  align-items: center;
  color: $main-black;
  opacity: 0.4;
  text-decoration: none;
  // white-space: nowrap;
  transition: opacity 0.32s ease;

  &__icon {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &Active {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 16px;
    text-decoration-color: $main-blue;
  }

  &MenuLinkActive {
    opacity: 1;
  }
}

@import "styles/mixins";
@import "styles/vars";

.root {
  display: grid;
  row-gap: 32px;
}

@mixin controlsFont {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;

  &__searchInput {
    @include controlsFont;
    height: 64px;
  }

  &__calendarInput {
    @include controlsFont;
    height: 64px;
  }

  &__deleteButton {
    @include controlsFont;
    padding: 0 20px;
    height: 64px;
    border-radius: 10px;
    background-color: $main-blue;
    color: $main-white;
  }
}

.table {
  &__header {
    background: rgba(242, 242, 242, 1);
    height: 60px;
    border-radius: 12px;
    overflow: hidden;

    & td {
      color: rgba(136, 136, 136, 1) !important;
    }
  }

  &__row {
    min-height: 60px;

    & td {
      padding: 20px !important;
      vertical-align: top;
    }
  }

  &__reviewText {
    text-align: start !important;
    overflow: visible !important;
    white-space: normal !important;
  }

  &__status {
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

.changeStatusButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;

  width: 159px;
  height: 52px;

  border-radius: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  text-align: center;

  border: none;
  outline: none;
  background-color: transparent;

  &_outline {
    color: $main-blue;
    border: 2px solid $main-blue;
  }

  &_solid {
    color: #ffffff;
    background: $main-blue;
  }

  &:hover {
    cursor: pointer;
  }
}

@import 'styles/mixins';

.changeCurrency {
  display: flex;
  flex-direction: column;
  padding: 75px 70px 50px 70px;

  &__title {
    @include font(26px, 600, 32px);
    margin: 0 0 32px 0;
  }

  &__button {
    @include font(18px, 600, 20px);
    padding: 18px 60px;
    width: fit-content !important;

    margin: 40px auto 0 auto;
  }
}

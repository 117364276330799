@import 'styles/mixins';
@import 'styles/vars';

.statisticCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 26px 42px 26px 18px;
  width: 100%;
  max-width: 500px;
  min-height: 128px;
  border-radius: 20px;
  background-color: $menu-background;

  &__title {
    @include font(20px, 300, 24px);
    width: 60%;
    margin-right: 32px;
  }

  &__value {
    @include font(40px, 500, 49px);
    margin-left: auto;

    &Pink {
      color: $main-pink;
    }

    &Blue {
      color: $main-blue;
    }
  }

  &__line {
    height: 100%;
    width: 6px;
    border-radius: 20px;
    margin-right: 22px;

    &Pink {
      background-color: $main-pink;
    }

    &Blue {
      background-color: $main-blue;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    font-size: 3rem;
    margin: 0 22px 0 22px;

    &Pink {
      & svg path {
        fill: $main-pink;
      }
    }

    &Blue {
      & svg path {
        fill: $main-blue;
      }
    }
  }
}

.statisticCardWithHint {
  display: flex;
  align-items: center;

  &__hint {
    @include font(16px, 300, 20px);
    margin-left: 32px;
    width: 132px;
  }
}

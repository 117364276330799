@import 'styles/mixins';

.complaintsReasons {
  &__select {
    @include font(16px, 300, 22px);
    padding: 10px 20px;
    min-width: 300px;

    & > span {
      display: inline;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__list {
    @include font(14px, 300, 18px);
    display: flex;
    flex-direction: column;
  }

  &__reason {
    margin: 4px 0;
    cursor: pointer;

    &All {
      margin-bottom: 16px;
    }
  }
}

@import 'styles/vars';

.app {
  display: grid;
  grid-template-columns: $menu-width 1fr;
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;

  &Mobile {
    grid-template-columns: $mobile-menu-width 1fr;
  }
}

.menu {
  width: $menu-width;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;

  &Fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: $menu-width;
    height: 100vh;
    overflow: hidden;
  }

  &Mobile {
    width: $mobile-menu-width;
  }

  &MobileFixed {
    width: $mobile-menu-width;
  }
}

@import 'styles/mixins';

.textModal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding:64px;
  width: 60vw;
  max-height: 80vh;

  &__title {
    @include font(24px, 500, 32px);
    margin: 0 0 16px 0;
  }

  &__text {
    @include font(26px, 300, 32px);
    margin-right: 32px;
    word-break: break-all;
    white-space: normal;
  }
}

@import 'styles/vars';
@import 'styles/mixins';

.select {
  box-sizing: border-box;
  position: relative;
  z-index: 4000;

  &__text {
    display: flex;
    align-items: center;
    margin-right: 16px;

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;

    user-select: none;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }

  &__button {
    @include normal-font;
    box-sizing: border-box;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 32px;
    background-color: $light-gray;
    border-radius: 10px;
    cursor: pointer;

    white-space: nowrap;

    transition: border-radius .2s ease-in-out;

    &:hover {
      background-color: $main-blue;
      color: $main-white;

      & .select__icon {
        & svg path {
          opacity: 1;
          stroke: $main-white !important;
        }
      }
    }

    &Open {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    font-size: 1.5em;

    & svg {
      transition: all 0.2s ease-in-out;
    }
  }

  &__body {
    position: relative;
    padding: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    background-color: $select-body;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &Overflow {
      position: absolute;
      width: 100%;
    }

    &Content {
      padding: 24px;
    }
  }
}
